body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #2b2b2b;
  color: #ddd;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
